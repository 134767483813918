<template>
    <div>
        <h1>About</h1>
        <DemoCarousel></DemoCarousel>
        <button @click="openModal" class="btn btn-primary">show modal</button>
        <modal name="demo-simple-modal" height="250px" width="200px">
            <h3>Simple Modal</h3>
            <button @click="closeModal" class="btn btn-danger">
                hide modal
            </button>
        </modal>

        <div class="demo-javascript-use">
            <div class="cntr">
                <div class="side">
                    <figure>
                        <figcaption>Native javascript</figcaption>
                        <pre><code>document.querySelectorAll('#exec-native .square')[0].addClass('black');</code></pre>
                    </figure>
                    <div id="exec-native">
                        <div class="square"></div>
                        <div class="square"></div>
                        <div class="square"></div>
                    </div>
                </div>
                <div class="side">
                    <figure>
                        <figcaption>JQuery</figcaption>
                        <pre><code>$('#exec-jquery .square').addClass('black');</code></pre>
                    </figure>
                    <div id="exec-jquery">
                        <div class="square"></div>
                        <div class="square"></div>
                        <div class="square"></div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import DemoCarousel from "@/components/demo/Carousel";

export default {
    name: "About",
    components: {
        DemoCarousel,
    },
    methods: {
        openModal() {
            this.$modal.show("demo-simple-modal");
        },
        closeModal() {
            this.$modal.hide("demo-simple-modal");
        },
    },
    mounted() {

        const $ = this.$jquery

        var hasClass = function (_className) {
            if (this.classList) {
                return this.classList.contains(_className);
            } else {
                var regcss = new RegExp(_className, "g");
                return regcss.test(this.className);
            }
        };
        var addClassNative = function (_className) {
            if (!this.hasClass(_className)) {
                if (this.classList) {
                    this.classList.add(_className);
                } else {
                    if (
                        typeof this.className === "undefined" ||
                        this.className === null
                    ) {
                        this.className = "";
                    }
                    this.className += (this.className ? " " : "") + _className;
                }
            }

            return this;
        };
        var removeClassNative = function (_className) {
            if (this.hasClass(_className)) {
                if (this.classList) {
                    this.classList.remove(_className);
                } else {
                    var regcss = new RegExp(_className, "g");
                    this.className = this.className.replace(regcss, "");
                }
            }
            return this;
        };

        if (!HTMLElement.prototype.hasClass) {
            HTMLElement.prototype.hasClass = hasClass;
        }

        if (!HTMLElement.prototype.addClassNative) {
            HTMLElement.prototype.addClassNative = addClassNative;
        }

        if (!HTMLElement.prototype.removeClassNative) {
            HTMLElement.prototype.removeClassNative = removeClassNative;
        }

        function nativeInterval(_index) {
            var squares = document.querySelectorAll("#exec-native .square");
            squares[_index % 3].addClassNative("black");
            squares[(_index + 2) % 3].removeClassNative("black");
        }

        function jqueryInterval(_index) {
            var squares = $("#exec-jquery .square");
            $(squares[_index % 3]).addClass("black");
            $(squares[(_index + 2) % 3]).removeClass("black");
        }

        var index = 0;
        setInterval(function () {
            nativeInterval(index);
            jqueryInterval(index);
            if (index >= 3) index = 0;
            index++;
        }, 1000);
    },
};
</script>
<style scoped>
body {
    color: #212121;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}
h4 {
    text-align: left;
    margin: 25px 0;
}

.cntr {
    font-size: 0;
}
.side {
    font-size: 12px;
    width: 50%;
    display: inline-block;
    padding: 0;
    margin: 0;
}

figure {
    font-size: 1.2em;
    color: #848484;
    line-height: 30px;
    border-radius: 1px;
    text-align: left;
    padding: 1px 15px;
    box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.3);
    overflow-x: scroll;
}

figcaption {
    font-weight: bold;
    color: #212121;
    font-size: 12px;
}

.square {
    width: 40px;
    height: 40px;
    background-color: #4cb648;
    border-radius: 2px;
    display: inline-block;
    margin: 20px;
    transition: ease 0.5s;
}

.black {
    background-color: #212121;
}
</style>