<template>
    <div class="DemoCarousel">
        <carousel
        :per-page="2"
        :mouseDrag="true"
        :navigationEnabled="true"
        :paginationEnabled="true"
        navigationNextLabel="▶"
        navigationPrevLabel="◀"
        paginationPosition="bottom">
            <slide>
                Slide 1 Content
            </slide>
            <slide>
                Slide 2 Content
            </slide>
            <slide>
                Slide 3 Content
            </slide>
            <slide>
                Slide 4 Content
            </slide>
            <slide>
                Slide 5 Content
            </slide>
        </carousel>
        <carousel
                :per-page="2"
                :mouseDrag="true"
                :navigationEnabled="true"
                :paginationEnabled="true"
                navigationNextLabel="▶"
                navigationPrevLabel="◀"
                paginationPosition="bottom">
            <slide>
                Slide 5 Content
            </slide>
            <slide>
                Slide 5 Content
            </slide>
            <slide>
                Slide 5 Content
            </slide>
            <slide>
                Slide 5 Content
            </slide>
            <slide>
                Slide 6 Content
            </slide>
        </carousel>
    </div>
</template>
<script>

import { Carousel, Slide } from 'vue-carousel'

export default {
    name: 'DemoCarousel',
    components: {
        Carousel,
        Slide,
    }
}
</script>
<style scoped>
.DemoCarousel {
    padding: 40px;
}
.VueCarousel-slide {
    position: relative;
    background: #42b983;
    color: #fff;
    font-family: Arial;
    font-size: 24px;
    text-align: center;
    min-height: 100px;
}
</style>